import "./src/styles/global.css";

//Try to fix wrong scrolltop after clicking on link (randomnly)
// see https://github.com/gatsbyjs/gatsby/issues/38201#issuecomment-1658071105
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual";
  const currentPosition = getSavedScrollPosition(location, location.key);
  if (!currentPosition) {
    console.log("test OK");
    window.scrollTo(0, 0);
  } else {
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        window.scrollTo(...currentPosition);
      });
    }, 0);
  }

  return false;
};
